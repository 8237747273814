import { useEffect, useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular, SquareRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { SendIcons, SendIconsDisabled, StopIcons } from "../common/Button";

interface Props {
  onSend: (question: string, id?: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
  stopGenerating: () => void;
  showLoadingMessage: boolean;
  isLoading: boolean;
  disableStop: boolean;
  defaultQues: string;
  setDefaultQues: (val: string) => void;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  conversationId,
  stopGenerating,
  showLoadingMessage,
  isLoading,
  defaultQues,
  disableStop,
  setDefaultQues,
}: Props) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim() || disableStop) {
      return;
    }

    if (conversationId) {
      onSend(question, conversationId);
    } else {
      onSend(question);
    }

    if (clearOnSend) {
      setQuestion("");
    }
  };
  useEffect(() => {
    setQuestion(defaultQues);
  }, [defaultQues]);
  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuestion(newValue || "");
    setDefaultQues(newValue || "");
  };

  const sendQuestionDisabled = disableStop || disabled || !question.trim();

  return (
    <>
      <Stack horizontal className={styles.questionInputContainer}>
        <TextField
          maxLength={2000}
          title={"Up to 2000 characters are allowed."}
          className={styles.questionInputTextArea}
          placeholder={placeholder}
          multiline
          resizable={false}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
        />
      </Stack>
      <Stack>
        {isLoading && !disableStop ? (
          <button
            title="Stop Generating Response"
            className={styles.questionInputSendButtonContainer}
            aria-label="Stop generating"
            tabIndex={0}
            onClick={stopGenerating}
            onKeyDown={(e) =>
              e.key === "Enter" || e.key === " " ? stopGenerating() : null
            }
          >
            <StopIcons />
          </button>
        ) : sendQuestionDisabled ? (
          <button
            className={styles.questionInputSendButtonContainer}
            aria-label="Send Disabled"
            tabIndex={0}
            disabled
            title={"Type something to enable."}
          >
            <SendIconsDisabled />
          </button>
        ) : (
          <button
            className={styles.questionInputSendButtonContainer}
            tabIndex={0}
            aria-label="Ask question button"
            onClick={sendQuestion}
            title="Ask a new question"
            onKeyDown={(e) =>
              e.key === "Enter" || e.key === " " ? sendQuestion() : null
            }
          >
            <SendIcons />
          </button>
        )}
        {/* <div className={styles.questionInputBottomBorder} /> */}
      </Stack>
    </>
  );
};

import {
  useRef,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import { Dialog, DialogType, Stack } from "@fluentui/react";
import { ErrorCircleRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import uuid from "react-uuid";
import { isEmpty } from "lodash-es";
import { ASSISTANT, ERROR, TOOL } from "../../../constants/constants";
import styles from "./Chat.module.css";

import {
  ChatMessage,
  conversationApi,
  ConversationRequest,
  Citation,
  ToolMessageContent,
  ChatResponse,
  Conversation,
  historyGenerate,
  historyUpdate,
  ChatHistoryLoadingState,
  CosmosDBStatus,
  ErrorMessage,
} from "../../../api";
import { Answer } from "../../../components/CarbonAIChat/Answer";
import { QuestionInput } from "../../../components/CarbonAIChat/QuestionInput";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import NoChat from "./NoChat";
import ChatLoader from "./ChatLoader";
import CitationPanel from "./CitationPanel";
import { ChatHistoryPanel } from "../../../components/CarbonAIChat/ChatHistory/ChatHistoryPanel";
import {
  Loader,
  UTCToLocaleDateTime,
} from "../../../components/CarbonAIChat/common/Common";
import Avatar from "../../../assets/Avatar-Conv.jpg";
import {
  CancelIcon,
  DoneIcon,
  EditIcon,
  SideBarOpen,
} from "../../../components/CarbonAIChat/common/Button";
const enum messageStatus {
  NotRunning = "Not Running",
  Processing = "Processing",
  Done = "Done",
}

const Chat = () => {
  const appStateContext = useContext(AppStateContext);
  const stopClick = useRef(false);
  const [disableStop, setDisableStop] = useState(false);
  const recalledQuestion = useRef<string>("");
  const [analyzing, setAnalyzing] = useState<boolean>(false);
  const [defaultQues, setDefaultQues] = useState<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [activeCitation, setActiveCitation] = useState<Citation>();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const abortFuncs = useRef([] as AbortController[]);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [processMessages, setProcessMessages] = useState<messageStatus>(
    messageStatus.NotRunning
  );
  const [clearingChat, setClearingChat] = useState<boolean>(false);
  const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
  const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
  const [userName, setUserName] = useState<string | null>("");
  const [userInitial, setUserInitial] = useState<string>("U");
  const [editQues, setEditQues] = useState<boolean>(false);
  let promptDetails = useRef({ title: "", summary: "" });
  const [editError, setEditError] = useState(false);
  const errorDialogContentProps = {
    type: DialogType.close,
    title: errorMsg?.title,
    closeButtonAriaLabel: "Close",
    subText: errorMsg?.subtitle,
  };

  const modalProps = {
    titleAriaId: "labelId",
    subtitleAriaId: "subTextId",
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };
  useEffect(() => {
    let timeout: number | undefined;
    if (showLoadingMessage) {
      timeout = setTimeout(() => {
        setAnalyzing(true);
      }, 2000);
    } else {
      clearTimeout(timeout);
      setAnalyzing(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showLoadingMessage]);
  useEffect(() => {
    if (
      appStateContext?.state.isCosmosDBAvailable?.status ===
        CosmosDBStatus.NotWorking &&
      appStateContext.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Fail &&
      hideErrorDialog
    ) {
      let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`;
      setErrorMsg({
        title: "Chat history is not enabled",
        subtitle: subtitle,
      });
      toggleErrorDialog();
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);

  const handleErrorDialogClose = () => {
    toggleErrorDialog();
    setTimeout(() => {
      setErrorMsg(null);
    }, 500);
  };

  /**
   * Returns the initials of a user's name.
   * @param name - The user's name.
   * @returns The initials of the user's name.
   */
  function getUserInitials(name: string): string {
    const nameParts = name.split(" ").filter(Boolean); // Split the name and remove any empty strings
    const initials = nameParts
      .map((part) => part[0])
      .join("")
      .toUpperCase(); // Extract and join initials
    return initials;
  }

  useEffect(() => {
    setIsLoading(
      appStateContext?.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Loading
    );

    // get user name from first session storage and set it to state
    setUserName(
      sessionStorage.getItem("isReportingHub") === "true"
        ? sessionStorage.getItem("RHUserName")
        : sessionStorage.getItem("msalUserName")
    );
  }, [appStateContext?.state.chatHistoryLoadingState]);

  useEffect(() => {
    // get user initials from first session storage and set it to state
    setUserInitial(getUserInitials(userName || ""));
  }, [userName]);

  let assistantMessage = {} as ChatMessage;
  let toolMessage = {} as ChatMessage;
  let assistantContent = "";

  const processResultMessage = (
    resultMessage: ChatMessage,
    userMessage: ChatMessage,
    conversationId?: string,
    language?: string,
    isEdited?: boolean,
    isRegenerated?: boolean
  ) => {
    resultMessage.language = language;
    resultMessage.isEdited = isEdited;
    resultMessage.isRegenerated = isRegenerated;
    if (resultMessage.role === ASSISTANT) {
      assistantContent += resultMessage.content;
      assistantMessage = resultMessage;
      assistantMessage.content = assistantContent;
    }

    if (resultMessage.role === TOOL) toolMessage = resultMessage;

    if (!conversationId) {
      isEmpty(toolMessage)
        ? setMessages([...messages, userMessage, assistantMessage])
        : setMessages([
            ...messages,
            userMessage,
            toolMessage,
            assistantMessage,
          ]);
    } else {
      isEmpty(toolMessage)
        ? setMessages([...messages, assistantMessage])
        : setMessages([...messages, toolMessage, assistantMessage]);
    }
  };

  const makeApiRequestWithoutCosmosDB = async (
    question: string,
    conversationId?: string
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: "user",
      content: question,
      date: new Date().toISOString(),
      summary: "",
      isEdited: false,
      isRegenerated: false,
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuid(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
        summary: "",
      };
    } else {
      conversation = appStateContext?.state?.currentChat;
      if (!conversation) {
        console.error("Conversation not found.");
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController
        );
        return;
      } else {
        conversation.messages.push(userMessage);
      }
    }

    appStateContext?.dispatch({
      type: "UPDATE_CURRENT_CHAT",
      payload: conversation,
    });
    setMessages(conversation.messages);

    const request: ConversationRequest = {
      messages: [
        ...conversation.messages.filter((answer) => answer.role !== ERROR),
      ],
    };

    let result = {} as ChatResponse;
    try {
      const response = await conversationApi(request, abortController.signal);
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = "";
        const chunkDateWithoutCosmos = new Date().toISOString();
        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach((obj) => {
                obj.id = result.id;
                obj.date = chunkDateWithoutCosmos;
                obj.summary = promptDetails.current.summary;
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach((resultObj) => {
                processResultMessage(
                  resultObj,
                  userMessage,
                  conversationId,
                  result.language,
                  result.isEdited,
                  result.isRegenerated
                );
              });
              runningText = "";
            } catch {}
          });
        }
        conversation.messages.push(toolMessage, assistantMessage);
        appStateContext?.dispatch({
          type: "UPDATE_CURRENT_CHAT",
          payload: conversation,
        });
        setMessages([...messages, toolMessage, assistantMessage]);
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          "Oops! Something went wrong. Please give it another try. If you keep running into trouble, don't hesitate to reach out to our support team for help.";
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === "string") {
          errorMessage = result.error;
        }
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
          summary: promptDetails.current.summary,
          isEdited: false,
          isRegenerated: false,
        };
        conversation.messages.push(errorChatMsg);
        appStateContext?.dispatch({
          type: "UPDATE_CURRENT_CHAT",
          payload: conversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      );
      setProcessMessages(messageStatus.Done);
    }

    return abortController.abort();
  };

  const handleCardClick = (prompt: string) => {
    makeApiRequestWithCosmosDB(prompt, "");
  };

  const makeApiRequestWithCosmosDB = async (
    question: string,
    conversationId?: string,
    stop?: Boolean
  ): Promise<void> => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    setEditError(false);
    setEditQues(false);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: "user",
      content: question,
      date: new Date().toISOString(),
      summary: promptDetails.current.summary,
      isEdited: false,
      isRegenerated: false,
    };

    let request: ConversationRequest;
    let conversation;
    if (conversationId) {
      conversation = appStateContext?.state?.chatHistory?.find(
        (conv) => conv.id === conversationId
      );
      if (!conversation) {
        console.error("Conversation not found.");
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController
        );
        return;
      } else {
        conversation.messages.push(userMessage);
        request = {
          messages: [
            ...conversation.messages.filter((answer) => answer.role !== ERROR),
          ],
        };
      }
    } else {
      request = {
        messages: [userMessage].filter((answer) => answer.role !== ERROR),
      };
      setMessages(request.messages);
    }
    let result = {} as ChatResponse;
    try {
      const response = conversationId
        ? await historyGenerate(
            request,
            abortController.signal,
            stop,
            conversationId
          )
        : await historyGenerate(request, abortController.signal, stop);
      // if (stop) {
      //   stopClick.current = false;
      // }
      if (response?.ok) {
        setDisableStop(true);
      }
      if (!response?.ok) {
        if (response.status !== 200) {
          let errorChatMsg: ChatMessage = {
            id: uuid(),
            role: ERROR,
            language: "",
            content:
              "Oops! It looks like we're having a bit of trouble saving your answers right now. Please try again in a little while. If you're still running into issues, don't hesitate to reach out to our support team for help. We're here to assist you!",
            date: new Date().toISOString(),
            summary: promptDetails.current.summary,
            isEdited: false,
            isRegenerated: false,
          };
          let resultConversation;
          if (conversationId) {
            resultConversation = appStateContext?.state?.chatHistory?.find(
              (conv) => conv.id === conversationId
            );
            if (!resultConversation) {
              console.error("Conversation not found.");
              setIsLoading(false);
              setShowLoadingMessage(false);
              abortFuncs.current = abortFuncs.current.filter(
                (a) => a !== abortController
              );
              return;
            }
            resultConversation.messages.push(errorChatMsg);
          } else {
            setMessages([...messages, userMessage, errorChatMsg]);
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController
            );
            return;
          }
          appStateContext?.dispatch({
            type: "UPDATE_CURRENT_CHAT",
            payload: resultConversation,
          });
          setMessages([...resultConversation.messages]);
          return;
        }
      }
      if (response?.body) {
        const reader = response.body.getReader();
        const chunkDate = new Date().toISOString();
        let runningText = "";
        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach((obj) => {
                obj.id = result.id;
                obj.date = chunkDate;
                obj.language = result.language;
                obj.isEdited = result.isEdited;
                obj.isRegenerated = result.isRegenerated;
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach((resultObj) => {
                processResultMessage(
                  resultObj,
                  userMessage,
                  conversationId,
                  result.language,
                  result.isEdited,
                  result.isRegenerated
                );
              });
              runningText = "";
              stopClick.current = false;
              setDisableStop(false);
            } catch {}
          });
        }
      }
    } catch (e) {
      if (!showLoadingMessage) return;
      if (!abortController.signal.aborted) {
        let errorMessage =
          "Oops! Something went wrong. Please give it another try. If you keep running into trouble, don't hesitate to reach out to our support team for help.";
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === "string") {
          errorMessage = result.error;
        }
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          language: "",
          date: new Date().toISOString(),
          summary: promptDetails.current.summary,
          isEdited: false,
          isRegenerated: false,
        };
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            (conv) => conv.id === conversationId
          );
          if (!resultConversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController
            );
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          if (!result.history_metadata) {
            console.error("Error retrieving data.", result);
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController
            );
            return;
          }
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date,
          };
          resultConversation.messages.push(errorChatMsg);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            (a) => a !== abortController
          );
          return;
        }
        appStateContext?.dispatch({
          type: "UPDATE_CURRENT_CHAT",
          payload: resultConversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      if (stopClick.current === false) {
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            (conv) => conv.id === conversationId
          );
          if (!resultConversation) {
            console.error("Conversation not found.");
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController
            );
            return;
          }
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        } else {
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date,
          };
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            (a) => a !== abortController
          );
          return;
        }
        appStateContext?.dispatch({
          type: "UPDATE_CURRENT_CHAT",
          payload: resultConversation,
        });
        isEmpty(toolMessage)
          ? setMessages([...messages, assistantMessage])
          : setMessages([...messages, toolMessage, assistantMessage]);
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController
        );
        setProcessMessages(messageStatus.Done);
      }
    }
    if (stopClick.current) {
      if (conversationId !== "") {
        conversation = appStateContext?.state?.chatHistory?.find(
          (conv) => conv.id === conversationId
        );

        conversation?.messages.pop();
      }
      setProcessMessages(messageStatus.NotRunning);
      return makeApiRequestWithCosmosDB(question, conversationId, true);
    }
    return abortController.abort();
  };

  const newChat = () => {
    setProcessMessages(messageStatus.Processing);
    setMessages([]);
    setDefaultQues("");
    setEditQues(false);
    setIsCitationPanelOpen(false);
    setActiveCitation(undefined);
    appStateContext?.dispatch({ type: "UPDATE_CURRENT_CHAT", payload: null });
    if (
      appStateContext?.state.isSmallScreen &&
      appStateContext?.state.activeStack === "history"
    )
      appStateContext.dispatch({ type: "SET_ACTIVE_STACK", payload: "chat" });
    setProcessMessages(messageStatus.Done);
  };

  const stopGenerating = () => {
    setDisableStop(true);
    if (messages[messages.length - 1]?.role === "user")
      stopClick.current = true;
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setEditQues(false);
    if (appStateContext?.state.currentChat) {
      promptDetails.current.summary = appStateContext.state.currentChat.summary
        ? appStateContext.state.currentChat.summary
        : "";
      promptDetails.current.title = appStateContext.state.currentChat.title;
      setMessages(appStateContext.state.currentChat.messages);
    } else {
      setMessages([]);
    }
  }, [appStateContext?.state.currentChat]);

  useLayoutEffect(() => {
    const saveToDB = async (messages: ChatMessage[], id: string) => {
      const response = await historyUpdate(messages, id);
      return response;
    };
    if (
      appStateContext &&
      appStateContext.state.currentChat &&
      processMessages === messageStatus.Done
    ) {
      if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
        if (!appStateContext?.state.currentChat?.messages) {
          console.error("Failure fetching current chat state.");
          return;
        }
        saveToDB(
          appStateContext.state.currentChat.messages,
          appStateContext.state.currentChat.id
        )
          .then(async (res) => {
            if (!res.ok) {
              let errorMessage =
                "Oops! It looks like we're having a bit of trouble saving your answers right now. Please try again in a little while. If you're still running into issues, don't hesitate to reach out to our support team for help. We're here to assist you!";
              let errorChatMsg: ChatMessage = {
                id: uuid(),
                language: "",
                role: ERROR,
                content: errorMessage,
                date: new Date().toISOString(),
                summary: promptDetails.current.summary,
                isEdited: false,
                isRegenerated: false,
              };
              if (!appStateContext?.state.currentChat?.messages) {
                let err: Error = {
                  ...new Error(),
                  message: "Failure fetching current chat state.",
                };
                throw err;
              }
              setMessages([
                ...appStateContext?.state.currentChat?.messages,
                errorChatMsg,
              ]);
            }
            if (res?.body && appStateContext.state.currentChat) {
              const reader = res.body.getReader();
              let text = {
                title: appStateContext.state.currentChat.title,
                summary: appStateContext.state.currentChat.summary,
              };
              while (true) {
                setProcessMessages(messageStatus.Processing);
                const { done, value } = await reader.read();
                if (done) {
                  if (text.summary != undefined && text.title != undefined) {
                    appStateContext.state.currentChat.title = text.title;
                    appStateContext.state.currentChat.summary = text.summary;
                    promptDetails.current.summary = text.summary;
                    promptDetails.current.title = text.title;
                  }
                  appStateContext.state.currentChat.date =
                    messages[messages.length - 1].date;
                  appStateContext?.dispatch({
                    type: "UPDATE_CHAT_HISTORY",
                    payload: appStateContext.state.currentChat,
                  });

                  setMessages(appStateContext.state.currentChat.messages);
                  setProcessMessages(messageStatus.NotRunning);
                  setDisableStop(false);
                  break;
                }
                text = {
                  ...JSON.parse(new TextDecoder("utf-8").decode(value)),
                };
              }
            }
            return res as Response;
          })
          .catch((err) => {
            console.error("Error: ", err);
            let errRes: Response = {
              ...new Response(),
              ok: false,
              status: 500,
            };
            return errRes;
          });
        // if (appStateContext.state.currentChat.title != undefined) {
        //   appStateContext.state.currentChat.date =
        //     messages[messages.length - 1].date;
        //   appStateContext?.dispatch({
        //     type: "UPDATE_CHAT_HISTORY",
        //     payload: appStateContext.state.currentChat,
        //   });

        //   setMessages(appStateContext.state.currentChat.messages);
        //   setProcessMessages(messageStatus.NotRunning);
        // }
      }
    }
  }, [processMessages]);

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [showLoadingMessage, processMessages, messages]);

  const onShowCitation = (citation: Citation) => {
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
    if (
      appStateContext?.state.isSmallScreen &&
      appStateContext?.state.activeStack === "chat"
    )
      appStateContext.dispatch({
        type: "SET_ACTIVE_STACK",
        payload: "citation",
      });
  };

  const editQuesFunction = (val: string) => {
    recalledQuestion.current = val;
    setEditQues(true);
  };

  const reCallQuestion = async (
    questionId: string,
    answerId: string,
    toolId?: string
  ) => {
    if (recalledQuestion.current.trim().length === 0) {
      setEditError(true);
      return false;
    }
    if (appStateContext?.state.currentChat?.id != undefined) {
      const currentData = { ...appStateContext?.state.currentChat };
      for (let i = currentData.messages.length - 1; i > -1; i--) {
        if (currentData.messages[i].id === questionId) {
          currentData.messages[i].isEdited = true;
          currentData.messages[i + 1].isEdited = true;
          if (toolId) currentData.messages[i + 2].isEdited = true;
          break;
        }
      }
      appStateContext?.dispatch({
        type: "UPDATE_CURRENT_CHAT",
        payload: { ...currentData },
      });
      setEditQues(false);
      await makeApiRequestWithCosmosDB(
        recalledQuestion.current,
        appStateContext?.state.currentChat?.id
      );
    }
  };
  const reGenAnswer = async (
    questionId: string,
    question: string,
    toolId?: string
  ) => {
    if (appStateContext?.state.currentChat?.id != undefined) {
      const currentData = { ...appStateContext?.state.currentChat };
      for (let i = currentData.messages.length - 1; i > -1; i--) {
        if (currentData.messages[i].id === questionId) {
          currentData.messages[i].isRegenerated = true;
          currentData.messages[i + 1].isRegenerated = true;
          if (toolId) currentData.messages[i + 2].isRegenerated = true;
          break;
        }
      }
      appStateContext?.dispatch({
        type: "UPDATE_CURRENT_CHAT",
        payload: { ...currentData },
      });
      setEditQues(false);
      await makeApiRequestWithCosmosDB(
        question,
        appStateContext?.state.currentChat?.id
      );
    }
  };

  const updateQuestion = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setEditError(false);
    recalledQuestion.current = event.target.value || "";
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role && message?.role === "tool") {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const disabledButton = () => {
    return (
      isLoading ||
      (messages && messages.length === 0) ||
      clearingChat ||
      appStateContext?.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Loading
    );
  };

  return (
    <main
      className={
        appStateContext?.state.isSmallScreen
          ? `${styles.containerSmallScreen} bgColorPrimary borderAll`
          : `${styles.container} bgColorPrimary borderAll`
      }
    >
      {appStateContext?.state.isCosmosDBAvailable?.status ===
      CosmosDBStatus.NotConfigured ? (
        <Loader />
      ) : (
        <Stack
          className={"rowAlign"}
          style={{ overflow: "hidden", display: "flex" }}
        >
          {(appStateContext?.state.activeStack === "all" ||
            appStateContext?.state.activeStack === "history") && (
            <Stack.Item
              className={
                appStateContext?.state.activeStack === "history"
                  ? `${styles.flexSmallScreen} bgColorDefault borderRight`
                  : `bgColorDefault borderRight`
              }
            >
              <ChatHistoryPanel
                newChat={newChat}
                disabledButton={disabledButton}
                setDefaultQues={setDefaultQues}
              />
            </Stack.Item>
          )}
          {(appStateContext?.state.activeStack === "all" ||
            appStateContext?.state.activeStack === "allButHistory" ||
            appStateContext?.state.activeStack === "chat") && (
            <Stack.Item
              className="bgColorPrime borderAddition"
              style={{ width: "100%", overflow: "hidden" }}
            >
              <section className={"colAlign"}>
                <Stack.Item>
                  <Stack
                    key={messages.length}
                    className={`header stickyHeader bgColorDefault borderBottom ${styles.convHead}`}
                    style={{
                      height: "65px",
                    }}
                  >
                    <Stack.Item>
                      {!appStateContext?.state.isSmallScreen &&
                        appStateContext?.state.activeStack ===
                          "allButHistory" && (
                          // <StackItem>
                          <button
                            title="Show History"
                            className="btn backIcon"
                            onClick={() => {
                              appStateContext?.dispatch({
                                type: "SET_ACTIVE_STACK",
                                payload: "all",
                              });
                            }}
                          >
                            <SideBarOpen></SideBarOpen>
                          </button>
                          // </StackItem>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                      <div
                        style={{ padding: "0px 10px" }}
                        className={styles.title}
                      >
                        {appStateContext?.state.currentChat &&
                        appStateContext?.state.currentChat?.title
                          ? appStateContext?.state.currentChat?.title.length >
                            50
                            ? appStateContext?.state.currentChat?.title.slice(
                                0,
                                47
                              ) + "..."
                            : appStateContext?.state.currentChat?.title
                          : "New Conversation"}
                      </div>
                      {appStateContext?.state.currentChat ? (
                        <>
                          <div
                            style={{ padding: "0px 10px" }}
                            className={styles.chatDate}
                          >
                            {
                              UTCToLocaleDateTime(
                                appStateContext?.state.currentChat?.date
                              ).date
                            }
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                <Stack.Item className={`${styles.chatRoot} `}>
                  <Stack>
                    {!messages || messages.length < 1 ? (
                      <NoChat onCardClick={handleCardClick} />
                    ) : (
                      <Stack.Item
                        className={styles.chatMessageStream}
                        style={{ marginBottom: "0px" }}
                        role="log"
                      >
                        {messages.map((answer, index) => (
                          <>
                            {!answer.isEdited &&
                              !answer.isRegenerated &&
                              (answer.role === "user" ? (
                                <div
                                  key={`${index}${answer.isEdited}${answer.isRegenerated}`}
                                  className={styles.chatMessageUser}
                                  tabIndex={0}
                                >
                                  <div className={styles.userMsg}>
                                    <div className={styles.userMsgDiv}>
                                      <div className="colorSecondary marginBottom5">
                                        {userName} |&nbsp;
                                        {UTCToLocaleDateTime(answer.date).date}
                                        &nbsp;
                                        {UTCToLocaleDateTime(answer.date).time}
                                      </div>
                                      <div
                                        className={
                                          styles.chatMessageUserMessage
                                        }
                                      >
                                        {(index === messages.length - 3 &&
                                          messages[index + 1].role ===
                                            "tool") ||
                                        (index === messages.length - 2 &&
                                          messages[index + 1].role ===
                                            "assistant") ? (
                                          <>
                                            {editQues ? (
                                              <>
                                                <div>
                                                  <textarea
                                                    title={
                                                      "Up to 2000 characters are allowed."
                                                    }
                                                    maxLength={2000}
                                                    placeholder={answer.content}
                                                    className={
                                                      styles.editQuesText
                                                    }
                                                    onChange={(event) => {
                                                      updateQuestion(event);
                                                    }}
                                                    defaultValue={
                                                      answer.content
                                                    }
                                                  ></textarea>
                                                </div>
                                                <div className={styles.editBox}>
                                                  <div>
                                                    {editError && (
                                                      <label className="error">
                                                        Prompt is required.
                                                      </label>
                                                    )}
                                                  </div>
                                                  <div>
                                                    <button
                                                      className="btn"
                                                      onClick={() => {
                                                        messages[index + 1]
                                                          .role === "tool"
                                                          ? reCallQuestion(
                                                              answer.id,

                                                              messages[
                                                                index + 2
                                                              ].id,
                                                              messages[
                                                                index + 1
                                                              ].id
                                                            )
                                                          : reCallQuestion(
                                                              answer.id,
                                                              messages[
                                                                index + 1
                                                              ].id
                                                            );
                                                      }}
                                                      title={"Resend"}
                                                    >
                                                      <DoneIcon></DoneIcon>
                                                    </button>
                                                    <button
                                                      className="btn"
                                                      onClick={() => {
                                                        setEditError(false);
                                                        setEditQues(false);
                                                      }}
                                                      title={"Cancel"}
                                                    >
                                                      <CancelIcon></CancelIcon>
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div>{answer.content}</div>
                                                {!isLoading ? (
                                                  <div
                                                    className={styles.editBox}
                                                  >
                                                    <button
                                                      className="btn"
                                                      onClick={() => {
                                                        editQuesFunction(
                                                          answer.content
                                                        );
                                                      }}
                                                      title={"Edit"}
                                                    >
                                                      <EditIcon></EditIcon>
                                                    </button>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <div>{answer.content}</div>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className={`circle ${styles.userInitial}`}
                                    >
                                      {userInitial}
                                    </div>
                                  </div>
                                </div>
                              ) : answer.role === "assistant" ? (
                                <div
                                  key={`${index}${answer.isEdited}${answer.isRegenerated}`}
                                  className={styles.chatMessageGpt}
                                >
                                  <div className={styles.aIMsg}>
                                    <div
                                      className={`circle ${styles.systemInitial}`}
                                    >
                                      <img
                                        alt="avtar"
                                        src={Avatar}
                                        className={`${styles.aiIcon}`}
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className={styles.aIMsgDiv}>
                                      <div className="colorSecondary marginBottom5">
                                        CarbonAI |&nbsp;
                                        {UTCToLocaleDateTime(answer.date).date}
                                        &nbsp;
                                        {UTCToLocaleDateTime(answer.date).time}
                                      </div>

                                      <Answer
                                        isLast={index === messages.length - 1}
                                        isLoading={isLoading}
                                        answer={{
                                          language: answer.language,
                                          answer: answer.content,
                                          citations: parseCitationFromMessage(
                                            messages[index - 1]
                                          ),
                                          message_id: answer.id,
                                          feedback: answer.feedback,
                                        }}
                                        onCitationClicked={(c) =>
                                          onShowCitation(c)
                                        }
                                        reGenAnswer={reGenAnswer}
                                        oldDetails={
                                          index === messages.length - 1
                                            ? messages[index - 1]?.role ===
                                              "tool"
                                              ? {
                                                  oldQuestion:
                                                    messages[index - 2]
                                                      ?.content,
                                                  questionId:
                                                    messages[index - 2]?.id,
                                                  toolId:
                                                    messages[index - 1]?.id,
                                                }
                                              : {
                                                  oldQuestion:
                                                    messages[index - 1]
                                                      ?.content,
                                                  questionId:
                                                    messages[index - 1]?.id,
                                                }
                                            : {
                                                oldQuestion: "",
                                                questionId: "",
                                              }
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : answer.role === ERROR ? (
                                <div
                                  key={`${index}${answer.isEdited}${answer.isRegenerated}`}
                                  className={styles.chatMessageGpt}
                                >
                                  <div className={styles.aIMsg}>
                                    <div
                                      className={`circle ${styles.loaderInitial}`}
                                    >
                                      <img
                                        alt="avtar"
                                        src={Avatar}
                                        className={`${styles.aiIcon}`}
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className={styles.aIMsgDiv}>
                                      <div className="colorSecondary marginBottom5">
                                        CarbonAI
                                      </div>
                                      <div className={styles.chatMessageError}>
                                        <Stack
                                          horizontal
                                          className={
                                            styles.chatMessageErrorContent
                                          }
                                        >
                                          <ErrorCircleRegular
                                            className={styles.errorIcon}
                                            style={{ color: "var(--error)" }}
                                          />

                                          <span>Error</span>
                                        </Stack>
                                        <span
                                          className={
                                            styles.chatMessageErrorContent
                                          }
                                        >
                                          {answer.content}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null)}
                          </>
                        ))}
                        {showLoadingMessage && (
                          <ChatLoader
                            analyzing={analyzing}
                            stopClick={stopClick.current}
                          />
                        )}
                        <div ref={chatMessageStreamEnd} />
                      </Stack.Item>
                    )}

                    <Stack>
                      <Dialog
                        hidden={hideErrorDialog}
                        onDismiss={handleErrorDialogClose}
                        dialogContentProps={errorDialogContentProps}
                        modalProps={modalProps}
                      ></Dialog>
                    </Stack>
                  </Stack>
                </Stack.Item>
                <Stack.Item className="header stickyHeader bgColorDefault borderTop">
                  <QuestionInput
                    isLoading={isLoading}
                    showLoadingMessage={showLoadingMessage}
                    stopGenerating={stopGenerating}
                    disableStop={disableStop}
                    defaultQues={defaultQues}
                    setDefaultQues={setDefaultQues}
                    clearOnSend
                    placeholder="Type a message when you're ready!"
                    disabled={isLoading}
                    onSend={(question, id) => {
                      appStateContext?.state.isCosmosDBAvailable?.cosmosDB
                        ? makeApiRequestWithCosmosDB(question, id)
                        : makeApiRequestWithoutCosmosDB(question, id);
                    }}
                    conversationId={
                      appStateContext?.state.currentChat?.id
                        ? appStateContext?.state.currentChat?.id
                        : undefined
                    }
                  />
                </Stack.Item>
              </section>
            </Stack.Item>
          )}

          {/* Citation Panel */}
          {(appStateContext?.state.activeStack === "all" ||
            appStateContext?.state.activeStack === "allButHistory" ||
            appStateContext?.state.activeStack === "citation") &&
            messages &&
            messages.length > 0 &&
            isCitationPanelOpen &&
            activeCitation && (
              <Stack.Item
                className={
                  appStateContext?.state.activeStack === "citation"
                    ? `${styles.flexSmallScreen} bgColorDefault borderLeft`
                    : `bgColorDefault borderLeft`
                }
                style={{ width: "30vw" }}
              >
                <CitationPanel
                  activeCitation={activeCitation}
                  setIsCitationPanelOpen={setIsCitationPanelOpen}
                />
              </Stack.Item>
            )}
        </Stack>
      )}
    </main>
  );
};

export default Chat;

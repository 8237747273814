import {
  FormEvent,
  useEffect,
  useMemo,
  useState,
  useContext,
  useRef,
} from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { dataSource, otherFeedbackValues } from "../../../constants/constants";
import {
  Checkbox,
  TextField,
  DefaultButton,
  Dialog,
  FontIcon,
  Stack,
  Text,
} from "@fluentui/react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";

import styles from "./Answer.module.css";

import {
  AskResponse,
  Citation,
  Feedback,
  historyMessageFeedback,
} from "../../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import { CopyIcon, ReGenIcon } from "../common/Button";

interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  isLoading: boolean;
  isLast: boolean;
  reGenAnswer: (questionId: string, question: string, toolId?: string) => void;
  oldDetails: {
    oldQuestion: string;
    questionId: string;
    toolId?: string;
  };
}

export const Answer = ({
  answer,
  onCitationClicked,
  isLoading,
  isLast,
  reGenAnswer,
  oldDetails,
}: Props) => {
  const initializeAnswerFeedback = (answer: AskResponse) => {
    if (answer.message_id == undefined) return undefined;
    if (answer.feedback == undefined) return undefined;
    if (Object.values(Feedback).includes(answer.feedback))
      return answer.feedback;
    return Feedback.Neutral;
  };

  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);
  const [feedbackState, setFeedbackState] = useState(
    initializeAnswerFeedback(answer)
  );
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [showReportInappropriateFeedback, setShowReportInappropriateFeedback] =
    useState(false);
  const [negativeFeedbackList, setNegativeFeedbackList] = useState<Feedback[]>(
    []
  );
  const otherFeedback = useRef<string>("");
  const [feedbackError, setFeedbackError] = useState(false);
  const appStateContext = useContext(AppStateContext);
  const FEEDBACK_ENABLED =
    appStateContext?.state.frontendSettings?.feedback_enabled;

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);

  useEffect(() => {
    if (answer.message_id == undefined) return;

    let currentFeedbackState;
    if (
      appStateContext?.state.feedbackState &&
      appStateContext?.state.feedbackState[answer.message_id]
    ) {
      currentFeedbackState =
        appStateContext?.state.feedbackState[answer.message_id];
    } else {
      currentFeedbackState = initializeAnswerFeedback(answer);
    }
    setFeedbackState(currentFeedbackState);
  }, [appStateContext?.state.feedbackState, feedbackState, answer.message_id]);

  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation.filepath.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation.filepath} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      }
    } else if (citation.filepath && citation.reindex_id) {
      citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  const onLikeResponseClicked = async () => {
    if (answer.message_id == undefined) return;

    let newFeedbackState = feedbackState;
    // Set or unset the thumbs up state
    if (feedbackState == Feedback.Positive) {
      newFeedbackState = Feedback.Neutral;
    } else {
      newFeedbackState = Feedback.Positive;
    }
    appStateContext?.dispatch({
      type: "SET_FEEDBACK_STATE",
      payload: { answerId: answer.message_id, feedback: newFeedbackState },
    });
    setFeedbackState(newFeedbackState);

    // Update message feedback in db
    await historyMessageFeedback(answer.message_id, newFeedbackState);
  };

  const onDislikeResponseClicked = async () => {
    if (answer.message_id == undefined) return;

    let newFeedbackState = feedbackState;
    if (
      feedbackState === undefined ||
      feedbackState === Feedback.Neutral ||
      feedbackState === Feedback.Positive
    ) {
      newFeedbackState = Feedback.Negative;
      setFeedbackState(newFeedbackState);
      setIsFeedbackDialogOpen(true);
    } else {
      // Reset negative feedback to neutral
      newFeedbackState = Feedback.Neutral;
      setFeedbackState(newFeedbackState);
      await historyMessageFeedback(answer.message_id, Feedback.Neutral);
    }
    appStateContext?.dispatch({
      type: "SET_FEEDBACK_STATE",
      payload: { answerId: answer.message_id, feedback: newFeedbackState },
    });
  };
  const onDislikePopUpHide = async () => {
    if (answer.message_id == undefined) return;

    let newFeedbackState = feedbackState;
    if (feedbackState == Feedback.Positive) {
      newFeedbackState = Feedback.Neutral;
    } else {
      newFeedbackState = Feedback.Negative;
    }

    appStateContext?.dispatch({
      type: "SET_FEEDBACK_STATE",
      payload: { answerId: answer.message_id, feedback: newFeedbackState },
    });
    setFeedbackState(newFeedbackState);

    // Update message feedback in db
    await historyMessageFeedback(answer.message_id, newFeedbackState);
  };

  const updateFeedbackList = (
    ev?: FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    if (answer.message_id == undefined) return;

    let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

    let feedbackList = negativeFeedbackList.slice();
    if (checked) {
      feedbackList.push(selectedFeedback);
    } else {
      feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
    }

    setNegativeFeedbackList(feedbackList);
  };

  const onSubmitNegativeFeedback = async () => {
    setFeedbackError(false);
    if (answer.message_id == undefined) return;
    if (
      negativeFeedbackList.includes(Feedback.OtherUnhelpful) &&
      otherFeedback.current.trim().length === 0
    ) {
      setFeedbackError(true);
      return;
    }
    let payload = negativeFeedbackList.map((item) => {
      if (item === Feedback.OtherUnhelpful)
        return `Others - ${otherFeedback.current.trim()}`;
      else return item;
    });
    await historyMessageFeedback(answer.message_id, payload.join(","));
    resetFeedbackDialog();
  };

  const resetFeedbackDialog = () => {
    otherFeedback.current = "";
    setFeedbackError(false);
    setIsFeedbackDialogOpen(false);
    setShowReportInappropriateFeedback(false);
    setNegativeFeedbackList([]);
  };

  const updateExtraFeedback = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setFeedbackError(false);
    otherFeedback.current = newValue ?? "";
  };
  const UnhelpfulFeedbackContent = () => {
    return (
      <>
        <div>Why wasn't this response helpful?</div>
        <Stack tokens={{ childrenGap: 4 }}>
          <Checkbox
            label="Did not address my question"
            id={Feedback.WrongQuestionAddress}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.WrongQuestionAddress
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Factually incorrect"
            id={Feedback.IncorrectFact}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.IncorrectFact
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Outdated"
            id={Feedback.Outdated}
            defaultChecked={negativeFeedbackList.includes(Feedback.Outdated)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Irrelevant to my query"
            id={Feedback.Irrelevant}
            defaultChecked={negativeFeedbackList.includes(Feedback.Irrelevant)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Difficult to understand"
            id={Feedback.Difficult}
            defaultChecked={negativeFeedbackList.includes(Feedback.Difficult)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Citations are missing or incorrect"
            id={Feedback.CitationIncorrect}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.CitationIncorrect
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Technical or formatting issue"
            id={Feedback.TechOrFormatIssue}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.TechOrFormatIssue
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Other"
            id={Feedback.OtherUnhelpful}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.OtherUnhelpful
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
        </Stack>
        <div
          onClick={() => setShowReportInappropriateFeedback(true)}
          style={{ color: "var(--secondary)", cursor: "pointer" }}
        >
          Report inappropriate content
        </div>
        {negativeFeedbackList.includes(Feedback.OtherUnhelpful) && (
          <>
            <div>
              <TextField
                className={styles.questionInputTextArea}
                placeholder={otherFeedbackValues.placeholder}
                title={otherFeedbackValues.title}
                multiline
                maxLength={100}
                resizable={false}
                defaultValue={otherFeedback.current}
                onChange={updateExtraFeedback}
              />
            </div>
            {feedbackError && (
              <div className="error">{otherFeedbackValues.error}</div>
            )}
          </>
        )}
      </>
    );
  };

  const ReportInappropriateFeedbackContent = () => {
    return (
      <>
        <div>
          The content is <span style={{ color: "var(--error)" }}>*</span>
        </div>
        <Stack tokens={{ childrenGap: 4 }}>
          <Checkbox
            label="Hate speech, stereotyping, demeaning"
            id={Feedback.HateSpeech}
            defaultChecked={negativeFeedbackList.includes(Feedback.HateSpeech)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Violent: glorification of violence, self-harm"
            id={Feedback.Violent}
            defaultChecked={negativeFeedbackList.includes(Feedback.Violent)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Sexual: explicit content, grooming"
            id={Feedback.Sexual}
            defaultChecked={negativeFeedbackList.includes(Feedback.Sexual)}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Manipulative: devious, emotional, pushy, bullying"
            defaultChecked={negativeFeedbackList.includes(
              Feedback.Manipulative
            )}
            id={Feedback.Manipulative}
            onChange={updateFeedbackList}
          ></Checkbox>
          <Checkbox
            label="Other"
            id={Feedback.OtherHarmful}
            defaultChecked={negativeFeedbackList.includes(
              Feedback.OtherHarmful
            )}
            onChange={updateFeedbackList}
          ></Checkbox>
        </Stack>
      </>
    );
  };
  const AnswerFooterUX = ({
    messageId,
    isLast,
  }: {
    messageId: string;
    isLast: boolean;
  }) => {
    return (
      <Stack.Item className={styles.answerHeader}>
        {FEEDBACK_ENABLED && (
          <Stack horizontal horizontalAlign="space-between">
            <ThumbLike20Filled
              aria-hidden="false"
              aria-label="Like this response"
              onClick={() => onLikeResponseClicked()}
              style={
                feedbackState === Feedback.Positive ||
                appStateContext?.state.feedbackState[messageId] ===
                  Feedback.Positive
                  ? { color: "var(--secondary)", cursor: "pointer" }
                  : { color: "var(--sub)", cursor: "pointer" }
              }
            />
            <ThumbDislike20Filled
              aria-hidden="false"
              aria-label="Dislike this response"
              onClick={() => onDislikeResponseClicked()}
              style={
                feedbackState !== Feedback.Positive &&
                feedbackState !== Feedback.Neutral &&
                feedbackState !== undefined
                  ? { color: "var(--error)", cursor: "pointer" }
                  : { color: "var(--sub)", cursor: "pointer" }
              }
            />
          </Stack>
        )}
        <Stack>
          <CopyIcon
            answerText={parsedAnswer.markdownFormatText}
            color={"default"}
          />
        </Stack>
        {isLast && (
          <Stack>
            <button
              className={`btn ${styles.reGenBtn}`}
              onClick={() => {
                reGenAnswer(
                  oldDetails.questionId,
                  oldDetails.oldQuestion,
                  oldDetails.toolId
                );
              }}
            >
              <ReGenIcon color={"default"}></ReGenIcon>
            </button>
          </Stack>
        )}
      </Stack.Item>
    );
  };

  return (
    <>
      <Stack className={styles.answerContainer} tabIndex={0}>
        <Stack.Item>
          <Stack horizontal grow>
            <Stack.Item grow>
              {answer.language !== "" && answer.language !== undefined && (
                <div>
                  <p className={styles.dataSource}>{answer.language}</p>
                </div>
              )}
              <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm, supersub]}
                children={parsedAnswer.markdownFormatText}
                className={styles.answerText}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>

        <Stack
          horizontal
          className={
            parsedAnswer.citations.length > 0
              ? styles.answerFooter
              : `${styles.answerFooter} ${styles.answerFooterJustify}`
          }
        >
          {!!parsedAnswer.citations.length && (
            <Stack.Item
              onKeyDown={(e) =>
                e.key === "Enter" || e.key === " "
                  ? toggleIsRefAccordionOpen()
                  : null
              }
            >
              <Stack style={{ width: "100%" }}>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                >
                  <Text
                    className={styles.accordionTitle}
                    onClick={toggleIsRefAccordionOpen}
                    aria-label="Open references"
                    tabIndex={0}
                    role="button"
                  >
                    <span>
                      {parsedAnswer.citations.length > 1
                        ? parsedAnswer.citations.length + " references"
                        : "1 reference"}
                    </span>
                  </Text>
                  <FontIcon
                    className={styles.accordionIcon}
                    onClick={handleChevronClick}
                    iconName={
                      chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                    }
                  />
                </Stack>
              </Stack>
            </Stack.Item>
          )}
          {!isLast && answer.message_id !== undefined ? (
            <AnswerFooterUX
              messageId={answer.message_id}
              isLast={false}
            ></AnswerFooterUX>
          ) : (
            !isLoading &&
            answer.message_id !== undefined && (
              <AnswerFooterUX
                messageId={answer.message_id}
                isLast={true}
              ></AnswerFooterUX>
            )
          )}
        </Stack>
        {chevronIsExpanded && (
          <div
            style={{
              marginTop: 8,
              display: "flex",
              flexFlow: "column",
              gap: "4px",
            }}
          >
            {parsedAnswer.citations.map((citation, idx) => {
              return (
                <span
                  title={createCitationFilepath(citation, ++idx)}
                  tabIndex={0}
                  role="link"
                  key={idx}
                  onClick={() => onCitationClicked(citation)}
                  onKeyDown={(e) =>
                    e.key === "Enter" || e.key === " "
                      ? onCitationClicked(citation)
                      : null
                  }
                  className={styles.citationContainer}
                  aria-label={createCitationFilepath(citation, idx)}
                >
                  <div className={styles.citation}>{idx}</div>
                  {createCitationFilepath(citation, idx, true)}
                </span>
              );
            })}
          </div>
        )}
      </Stack>
      <Dialog
        onDismiss={async () => {
          resetFeedbackDialog();
          await onDislikePopUpHide();
        }}
        hidden={!isFeedbackDialogOpen}
        styles={{
          main: [
            {
              selectors: {
                ["@media (min-width: 480px)"]: {
                  maxWidth: "600px",
                  background: "#FFFFFF",
                  boxShadow:
                    "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  maxHeight: "600px",
                  minHeight: "100px",
                },
              },
            },
          ],
        }}
        dialogContentProps={{
          title: "Submit Feedback",
          showCloseButton: true,
        }}
      >
        <Stack tokens={{ childrenGap: 4 }}>
          <div>Your feedback will improve this experience.</div>

          {!showReportInappropriateFeedback ? (
            <UnhelpfulFeedbackContent />
          ) : (
            <ReportInappropriateFeedbackContent />
          )}

          <div>
            By pressing submit, your feedback will be visible to the application
            owner.
          </div>

          <DefaultButton
            className="defaultBtn"
            disabled={negativeFeedbackList.length < 1}
            onClick={onSubmitNegativeFeedback}
          >
            Submit
          </DefaultButton>
        </Stack>
      </Dialog>
    </>
  );
};
